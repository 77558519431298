import { Component, Input } from '@angular/core';
import { UsersService } from '../../services/users.service';
import {
  User,
  reference,
} from '../../../core/interfaces/interfaces.interfaces';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { GlobalFunctionService } from '../../services/function.service';
import { PaymentService, tier } from '../../services/payment.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.html',
  styleUrl: './header.scss',
})
export class HeaderComponent {
  reference: reference = {
    code: '',
    created_at: '',
  };
  is_admin: boolean | undefined = false;
  user: User | null = null;
  account: string = '';
  constructor(
    public userService: UsersService,
    public config: NgbModalConfig,
    private modalService: NgbModal,
    public functions: GlobalFunctionService,
    public paymentService: PaymentService,
    public router: Router,
    public location: Location,
    public usersService: UsersService,
  ) {
    config.size = 'lg';
    config.centered = true;
  }
  bool_suscription = false;

  ngOnInit(): void {
    this.router.events.subscribe((route: any) => {
      if (window.location.pathname === '/login') {
        this.modalService.dismissAll();
      }
    });

    this.functions.current_subscription.subscribe((bool) => {
      this.bool_suscription = bool;
    });

    const user = this.validateUser();
    if (user) {
      if (user.is_superuser) {
        this.is_admin = user.is_superuser;
        this.userService.user = user;
      } else {
        this.usersService.get_user().subscribe((user) => {
          this.userService.user = user;
          if (user) {
            this.user = user;
          }
          if (!user.is_free_user) {
            this.getsubscription();
          }
        });
      }
    }
  }

  validateUser = (): User | null => {
    const local_user = localStorage.getItem('USER') || null;
    if (local_user) {
      const user = JSON.parse(local_user);
      return user;
    }
    return null;
  };
  getsubscription() {
    this.paymentService.get_my_subscription().subscribe((resp) => {
      const status = this.functions.validate_status(resp);
      this.functions.updateSuscription(status);
    });
  }
  showModal(modal: any) {
    this.userService.getCodeUser().subscribe((resp) => {
      if (resp.code === '') {
        this.userService.generateCodeUser().subscribe((resp) => {
          this.reference = resp;
          this.modalService.open(modal);
        });
      } else {
        this.reference = resp;
        this.modalService.open(modal);
      }
    });
  }

  navigateHome = () => {
    const url = this.is_admin ? '/admin/clients' : '/client/inicio';
    if (this.is_admin) {
      this.router.navigateByUrl(url);
    } else {
      if (this.bool_suscription) {
        this.router.navigateByUrl(url);
      }
    }
  };
  generateAccount() {
    this.functions.generate_account();
  }
}
