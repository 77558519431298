import { CanActivateFn, Router } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { User } from '../interfaces/interfaces.interfaces';
import { GlobalFunctionService } from '../../routes/services/function.service';
import { Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
class UserService {
  constructor(
    public location: Location,
    public functions: GlobalFunctionService,
    public route: Router,
  ) {}
  canActivate(): boolean {
    const loca_user = localStorage.getItem('USER');
    const user: User | null = loca_user ? JSON.parse(loca_user) : null;
    if (user?.is_superuser && user?.is_staff) {
      this.functions.alertAnimation(
        'error',
        'Error',
        'No tienes acceso a esta pantalla',
      );
      this.location.back();
      return false;
    }
    return true;
  }
}

export const usersClientGuard: CanActivateFn = (route, state) => {
  return inject(UserService).canActivate();
};
