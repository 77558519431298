import { Component } from '@angular/core';
import { LayoutModule } from './layout.module';
import { ActivatedRoute, Route, Router, RouterModule } from '@angular/router';
import { MenuService, item } from '../services/menu.service';
import { CommonModule } from '@angular/common';
import { User } from '../../core/interfaces/interfaces.interfaces';
import { GlobalFunctionService } from '../services/function.service';
import { AuthService } from '../services/auth.service';
import { PaymentService } from '../services/payment.service';
import { UsersService } from '../services/users.service';

@Component({
  standalone: true,
  selector: 'app-layout',
  imports: [CommonModule, LayoutModule, RouterModule],
  templateUrl: './layout.html',
  styleUrl: './layout.scss',
})
export class LayoutComponent {
  menu: Array<item> = [];
  constructor(
    public menuService: MenuService,
    public functions: GlobalFunctionService,
    public authService: AuthService,
    public router: Router,
    public paymentService: PaymentService,
    public userService: UsersService,
  ) {}

  ngOnInit(): void {
    this.menu = this.menuService.validateMenu();
  }

  logout() {
    this.authService.logout().subscribe((resp) => {
      localStorage.removeItem('token');
      this.router.navigateByUrl('/login');
    });
  }
}
