import { CanActivateFn, Router } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { User } from '../interfaces/interfaces.interfaces';
import { GlobalFunctionService } from '../../routes/services/function.service';
import { Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
class UserService {
  constructor(
    public location: Location,
    public functions: GlobalFunctionService,
  ) {}
  canActivate(): boolean {
    try {
      const local_user = localStorage.getItem('USER');
      const user: User | null = local_user ? JSON.parse(local_user) : null;
      if (!user?.is_superuser) {
        this.functions.alertAnimation(
          'error',
          'Error',
          'No tienes acceso a esta pantalla',
        );
        this.location.back();
        return false;
      }
    } catch (error) {
      return false;
    }

    return true;
  }
}

export const usersAdminGuard: CanActivateFn = (route, state) => {
  return inject(UserService).canActivate();
};
