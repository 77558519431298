<nav class="navbar navbar-expand-lg bg-body-tertiary p-0">
  <div class="container-fluid">
    <div>
      <a class="navbar-brand pointer" (click)="navigateHome()">
        <img
          class="p-3"
          src="assets/images/logo_green.png"
          alt="logo"
          width="150"
        />
      </a>

      <em
        class="fa fa-bars pointer"
        data-bs-toggle="offcanvas"
        data-bs-target="#staticBackdrop"
        aria-controls="staticBackdrop"
      ></em>
    </div>
    @if (!is_admin) {
      <div
        class="card-header border-0 d-flex justify-content-center align-items-center gap-4 flex-wrap my-3 my-md-0"
      >
        @if (bool_suscription || userService.user?.is_free_user) {
          <div
            class="d-flex justify-content-between p-3"
            [routerLink]="'/client/metodos_de_pago'"
            [routerLinkActive]="' bg-dark text-white'"
          >
            <div
              class="d-flex justify-content-center align-items-center gap-3 pointer"
            >
              <em class="fa fa-vcard"></em>
              <p class="m-0">Metodos de pago</p>
            </div>
          </div>

          <div
            class="d-flex justify-content-between p-3"
            (click)="showModal(modalReference)"
          >
            <div
              class="d-flex justify-content-center align-items-center gap-3 pointer"
            >
              <em class="fa fa-key"></em>
              <p class="m-0">Mi codigo</p>
            </div>
          </div>
        }
      </div>
    }
  </div>
</nav>

<!-- Modal -->
<ng-template #modalReference let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" style="font-weight: 800">
      Mi codigo de referencia
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="d('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <app-reference-code [reference]="reference"></app-reference-code>
  </div>
</ng-template>
