import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { GlobalFunctionService } from '../../routes/services/function.service';
export const CACHING_ALERT_ERROR = new HttpContextToken<boolean>(() => true);

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private fucntions: GlobalFunctionService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.context.get(CACHING_ALERT_ERROR)) {
      return next.handle(req).pipe(
        catchError((error: HttpErrorResponse) => {
          const alert_error = (text: string, title: string) => {
            this.fucntions.alertAnimation('error', text, title);
          };
          let errorMessage = '';
          if (error.status === 400 && error.error) {
            const obj_err: any = error.error;
            for (const key in obj_err) {
              if (Object.prototype.hasOwnProperty.call(obj_err, key)) {
                if (
                  Array.isArray(obj_err[key]) &&
                  obj_err[key] &&
                  obj_err[key].length > 0
                ) {
                  obj_err[key].forEach((item: any) => {
                    alert_error(item, key);
                  });
                } else {
                  if (Object.is(obj_err[key], obj_err[key])) {
                    for (const error in obj_err[key]) {
                      alert_error(obj_err[key][error], key);
                    }
                  } else {
                    alert_error(obj_err[key], key);
                  }
                }
              }
            }
            errorMessage = ``;
          } else if (error.status === 500) {
            errorMessage = `Error: ${error.error.error}`;
            alert_error(error.error.error, `Error ${error.status}`);
          } else if (error.error.detail) {
            errorMessage = `Error: ${error.error.detail}`;
            alert_error(error.error.detail, error.status.toString());
          } else {
            if (error.error instanceof ErrorEvent) {
              errorMessage = `Error: ${error.error.message}`;
              alert_error('Error', error.error.message);
            }
          }
          return throwError(() => errorMessage);
        }),
      );
    }
    return next.handle(req);
  }
}
