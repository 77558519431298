<div class="container">
  <app-header></app-header>
  <div style="margin-top: 5%">
    <router-outlet></router-outlet>
  </div>
</div>

<div
  class="offcanvas offcanvas-start"
  tabindex="-1"
  id="staticBackdrop"
  aria-labelledby="staticBackdropLabel"
>
  <div class="offcanvas-header">
    <div></div>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
      style="cursor: pointer"
    ></button>
  </div>
  <div>
    <div class="d-flex justify-content-centera align-items-center flex-column">
      <div class="mb-4 icon-sidebar"></div>
      <h5 class="offcanvas-title" id="staticBackdropLabel">
        Bienvenido {{ userService.user?.first_name }}
      </h5>
    </div>
  </div>
  <div class="offcanvas-body p-0 mt-4">
    @if (
      (functions.current_subscription | async) ||
      userService.user?.is_superuser ||
      userService.user?.is_free_user
    ) {
      <ul class="navbar-nav p-0">
        <li
          class="nav-item"
          *ngFor="let item of menu"
          [routerLink]="[item.link]"
          routerLinkActive="active"
        >
          <a class="nav-link">{{ item.label }}</a>
          <em class="fa fa-2x" [class]="item.icon"></em>
        </li>
      </ul>
    }

    @if (
      !(functions.current_subscription | async) &&
      !userService.user?.is_superuser &&
      !userService.user?.is_free_user
    ) {
      <div
        class="d-flex justify-content-center align-items-center h-100 text-center"
      >
        Necesitas una suscripción para visualizar los datos
      </div>
    }
  </div>
  <div class="text-center">
    <div class="m-5" style="cursor: pointer" (click)="logout()">
      Cerrar sesión
    </div>
  </div>
</div>
