import { Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout';
import { tokenGuard } from '../core/guards/token.guard';
import { usersAdminGuard } from '../core/guards/users_admin.guard';
import { usersClientGuard } from '../core/guards/users_client.guard';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  {
    path: 'admin',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'clientes',
        pathMatch: 'full',
      },
      {
        path: '',
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
        canActivate: [tokenGuard, usersAdminGuard],
      },
    ],
  },
  {
    path: 'client',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'client/inicio',
        pathMatch: 'full',
      },
      {
        path: '',
        loadChildren: () =>
          import('./client/client.module').then((m) => m.ClientModule),
        canActivate: [tokenGuard, usersClientGuard],
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'client/inicio',
      },
    ],
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'login',
  },
];
