import { Injectable } from '@angular/core';
import { User } from '../../core/interfaces/interfaces.interfaces';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  admin: item[] = [
    /*     {
      link: '/admin/home',
      label: 'Home',
      icon: 'fa fa-home fa-2x',
    }, */
    {
      link: '/admin/clientes',
      label: 'Cliente',
      icon: 'fa fa-users fa-2x',
    },
    {
      link: '/admin/sobre_nosotros',
      label: 'Sobre nosotros',
      icon: 'fa fa-building fa-2x',
    },
  ];
  clients: item[] = [
    {
      link: '/client/inicio',
      label: 'Home',
      icon: 'fa fa-home fa-2x',
    },
    {
      link: '/client/historial_de_pagos',
      label: 'Historial de pagos',
      icon: 'fa fa-history fa-2x',
    },
  ];

  validateMenu(): Array<item> {
    /* validamos con el usuario logeado */
    const loca_user = localStorage.getItem('USER');
    const user: User | null = loca_user ? JSON.parse(loca_user) : null;

    if (user?.is_superuser && user?.is_staff) {
      return this.admin;
    }
    return this.clients;
  }
}

export interface item {
  link: string;
  label: string;
  icon: string;
}
