import { CanActivateFn, Router } from '@angular/router';
import { Injectable, inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
class TokenService {
  constructor(public route: Router) {}
  canActivate(): boolean {
    try {
      const token = localStorage.getItem('token') || false;
      if (!token) {
        this.route.navigateByUrl('/login');
        return false;
      }
    } catch (error) {
      return false;
    }

    return true;
  }
}

export const tokenGuard: CanActivateFn = (route, state) => {
  return inject(TokenService).canActivate();
};
